import React from "react";

import "./Report.css";
import { dataReport } from "../../data/DataReport";

const Report = () => {
  return (
    <>
      <div className="title-container">
        <p style={{ color: "white" }}>Brand Report</p>
      </div>
      <div className="report-container">
        {dataReport.map((item, i) => (
          <div key={i} className="report-card">
            <div>
              <img
                src={item.image}
                alt={item.title}
                width={350}
                height={200}
                className="image"
              />
            </div>
            <div>
              <p className="title">{item.title}</p>
            </div>
            <a
              className="button-report"
              href={item.link_report}
              target="_blank"
              rel="noreferrer"
            >
              {item.title}
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Report;
