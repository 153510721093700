export const dataReport = [
  {
    title: "Report PKT",
    link_report:
      "https://pkt.tms.my.id/index.html?file=PKT.pdf#megazineMode=true",
    image: "https://bucket.tms.id/media/logo_pkt.webp",
  },
  {
    title: "Report Pefindo",
    link_report: "",
    image: "https://bucket.tms.id/media/logo_idscore.webp",
  },
];
